<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value" :style="{ borderRadius: '50px' }"
          scrollable max-width="99%"
          persistent>          
  <v-card class="d-flex flex-column" min-height="98%">
    <v-card-title class="primary white--text">
      {{getTitle}}
      <v-spacer/>
      <v-btn text elevation="0" color="white" small @click="$emit('input', false)">
        Fechar <v-icon>mdi-close</v-icon>
      </v-btn>   
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">
      <v-form ref="form" @submit.prevent="save" v-model="formValid" lazy-validation>
        <DocGeradoForm v-model="item" :formValid="formValid"
         @onExecuta="habilitarLoading"/>
      </v-form>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="success" @click="$emit('input', false)">Cancelar</v-btn>
      <v-btn color="primary" @click="openExportMenu" :disabled="!formValid || loading">
        Exportar documento
      </v-btn>
      <responsive-menu ref="menu"
                  @click:item="exportReport"
                  title="Exportar" :opcoes="menuOptions"/>
    </v-card-actions>
  </v-card>
  <v-overlay :value="loading" absolute>
    <v-progress-circular
      indeterminate
      color="primary"
      class="ma-4"
    ></v-progress-circular>
  </v-overlay>
</v-dialog>
</template>

<script>
import DocGeradoForm from "../forms/DocGeradoForm";
import {downloadReport, ReportType} from "../../../api/relatorios";
import ResponsiveMenu from "../../app/ResponsiveMenu";
import {ApiError} from "../../../api/core";

export default {
  name: "CreateEditGerarDocDialog",
  components: {DocGeradoForm, ResponsiveMenu},
  props: ['value','item'],
  data () {
    return {
      formValid: false,
      loading: false,
      menuOptions: [
        {
          action: 'pdf',
          icon: "mdi-file-pdf",
          color: "red",
          text: 'para PDF',
          permiteAcao: true,
        },
        {
          action: 'doc',
          icon: "mdi-file-word-outline",
          color: "blue",
          text: 'para Word',
          permiteAcao: true,
        }
      ],
      report: ReportType.REL_DOC_GERADO
    }
  },
  computed: {
    getTitle() {
      return 'Gerar Documentos de: ' + this.item?.descricao +' - '+ this.item?.descricaoUnidade
    }
  },
  methods: {
    openExportMenu(ev) {
      if(!this.$refs.form.validate()) return;
      this.$refs.menu.open(ev);
    },
    async exportReport(v) {
      try {
        this.habilitarLoading(true);
        await downloadReport(this.report, {
          idLicitacao: this.item?.id,
          idDocumento: this.item?.idDocumento,
          formatoSaida: v,
        });
      } catch (err) {
        this.error = err;
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível exportar o documento`, {
            timeout: 6000
          })
        }
      } finally {
        this.habilitarLoading(false);
      }      
    },
    habilitarLoading(valor){
      console.log('habilitarLoading', valor);
      this.loading = valor;
    }
  },
}
</script>

<style scoped>

</style>
var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"* Descricao","dense":"","rules":[
                      this.$validators.string.required,
                          function (v) { return this$1.$validators.string.greaterThanOrEquals(v, 1); },
                      function (v) { return this$1.$validators.string.lessThanOrEquals(v, 200); }
                  ]},model:{value:(_vm.value.descricao),callback:function ($$v) {_vm.$set(_vm.value, "descricao", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"value.descricao"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('search-box',{attrs:{"label":"* Orgão","popup-label":"Selecione um Orgão","id":_vm.value.idOrgao,"descricaoFormatada":_vm.value.descricaoOrgao,"hidden-clear":false,"value":_vm.orgao,"loader-fn":_vm.loaderOrgao,"item-key":"id","item-text":"descricaoFormatada","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idOrgao", $event)},"update:descricaoFormatada":function($event){return _vm.$set(_vm.value, "descricaoOrgao", $event)},"update:descricao-formatada":function($event){return _vm.$set(_vm.value, "descricaoOrgao", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhum Orgão encontrado ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('search-box',{ref:"comboUnidade",attrs:{"label":"* Unidade Responsável","popup-label":"Selecione uma Unidade Responsável","id":_vm.value.idUnidade,"descricaoFormatada":_vm.value.descricaoUnidade,"hidden-clear":false,"value":_vm.unidade,"loader-fn":_vm.loaderUnidade,"item-key":"id","item-text":"descricaoFormatada","rules":[
            this.$validators.notNullOrUndefined
        ]},on:{"update:id":function($event){return _vm.$set(_vm.value, "idUnidade", $event)},"update:descricaoFormatada":function($event){return _vm.$set(_vm.value, "descricaoUnidade", $event)},"update:descricao-formatada":function($event){return _vm.$set(_vm.value, "descricaoUnidade", $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"ma-2"},[_vm._v(" Nenhuma Unidade encontrada ")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('CardPersonalizado',{attrs:{"title":"Adicionar Itens do Plano à Contratação","showConteudo":true,"loading":_vm.loading}},[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"* Busque o item pelo código ou descrição","dense":""},model:{value:(_vm.descItemPesquisa),callback:function ($$v) {_vm.descItemPesquisa=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"descItemPesquisa"}})],1),_c('v-divider')],1),_c('PacDataTable',{ref:"itensDoPacLicitacaoNovos",attrs:{"force-hidden-header":true,"search-values":{
            qtdRegPorPagina: 5,
            idsPacNotIn: this.value.idsPac,
            descItemPesquisa: this.descItemPesquisa,
          }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
          var items = ref.items;
return [_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((items),function(pac){return _c('v-list-item',{key:pac.id,staticClass:"itens-pac add",on:{"click":function($event){return _vm.addItemPac(pac)}}},[_c('v-list-item-avatar',[_vm._v(" #"+_vm._s(pac.id)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(pac.cdItem)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(pac.descricaoItem)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(pac.descricaoOrgao)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(pac.descricaoUnidade)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(pac.descricaoRegiao +' - '+ pac.descricaoAlmoxarifado)}})],1),_c('v-list-item-action-text',[_vm._v(" "+_vm._s(pac.descricaoUnidadeMedida)+" "),_c('br'),_vm._v(" "+_vm._s(pac.quantidade)+" ")])],1)}),1)]}}])})],1)])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('CardPersonalizado',{attrs:{"title":"Itens do Plano","showConteudo":true,"loading":_vm.loading}},[_c('div',[_c('v-list',{staticClass:"pa-0",attrs:{"dense":""}},_vm._l((_vm.pacsDaLicitacao),function(item){return _c('v-list-item',{key:item.id,staticClass:"itens-pac del",on:{"click":function($event){return _vm.removeItem(item)}}},[_c('v-list-item-avatar',[_vm._v(" #"+_vm._s(item.idPac)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.cdItem)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.descricaoItem)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.descricaoOrgao)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.descricaoUnidade)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.descricaoRegiao +' - '+ item.descricaoAlmoxarifado)}})],1),_c('v-list-item-action-text',[_vm._v(" "+_vm._s(item.descricaoUnidadeMedida)+" "),_c('br'),_vm._v(" "+_vm._s(item.quantidade)+" ")])],1)}),1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }